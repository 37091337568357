import {Slot} from '@radix-ui/react-slot'
import {grid, gridItem} from '~/design-system/foundations'
import {InfiniteMovingCards} from '~/design-system/foundations/InfiniteMovingCards'
import {tv} from 'tailwind-variants'

const logoMashVariants = tv({
  slots: {
    scrollerItem:
      'w-auto text-icon-default [&>*:first-child]:h-full [&_*]:!fill-icon-default [&_svg]:h-full',
  },
  variants: {
    hasCaptions: {
      true: {
        scrollerItem: 'h-62 md:h-84 3xl:h-100', // taller height when captions exist
      },
      false: {
        scrollerItem: 'md:h-64 3xl:h-80', // original height when no captions
      },
    },
  },
})

export type Logo = {
  logo: React.ReactNode
  companyName: string
  captionText?: string
}

export type HgLogoMashProps = {
  logos: [Logo, Logo, Logo, Logo, ...Logo[]]
  title?: string | React.ReactNode
}

export default function HgLogoMash(props: HgLogoMashProps) {
  const {logos, title} = props
  // Even though caption texts are optional, displaying them is all or none
  // so we only display them if all logos have a caption.
  // This is to ensure alignment is correct.
  const everyLogoHasCaptionText = logos.every(logo => logo.captionText)
  const {scrollerItem} = logoMashVariants({hasCaptions: everyLogoHasCaptionText})

  return (
    <div className="w-full">
      <div className={grid({className: 'mx-auto'})}>
        {title && (
          <p
            className={gridItem({
              size: 'heading',
              className: 'mt-s9 text-center text-text-default arcadia-subheading-5',
            })}
          >
            {title}
          </p>
        )}
        <InfiniteMovingCards
          items={logos.map((logo, idx) => (
            <div
              key={idx}
              className="flex h-full flex-col items-start justify-between"
            >
              <Slot aria-label={logo.companyName}>{logo.logo}</Slot>
              {logo.captionText && everyLogoHasCaptionText && (
                <p className="text-text-subdued arcadia-heading-12">
                  {logo.captionText}
                </p>
              )}
            </div>
          ))}
          classNames={{
            container: gridItem({size: 'max', className: 'mt-s6'}),
            scrollerItem: scrollerItem(),
          }}
          itemScrollDurationSeconds={2.5}
          itemGap="s10"
        />
      </div>
    </div>
  )
}
